import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import LoaderSectionMainPage from './Components/LoaderComponents/LoaderSectionMainPage';

// Lazy load major components
const App = React.lazy(() => import('./App'));

// Service Worker Registration with Update Handling
if ('serviceWorker' in navigator) {
  // Unregister any existing service workers first
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });

  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      // Force check for updates
      registration.update();

      setInterval(() => {
        registration.update();
      }, 1000 * 60); // Check for updates every minute

      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing;
        
        newWorker.addEventListener('statechange', () => {
          if (newWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // New content is available, force reload
              window.location.reload(true);
            }
          }
        });
      });
    });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // Force reload when the service worker takes control
      window.location.reload(true);
    });
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Suspense fallback={<LoaderSectionMainPage />}>
      <App />
    </Suspense>
  </BrowserRouter>
);

// Update web vitals reporting
reportWebVitals(metric => {
  // Send to analytics
  // console.log(metric);
});
