import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import LoaderSectionMainPage from './Components/LoaderComponents/LoaderSectionMainPage';

// Lazy load major components
const App = React.lazy(() => import('./App'));

// Service Worker Registration with Update Handling
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('ServiceWorker registration successful');
      })
      .catch(error => {
        console.log('ServiceWorker registration failed:', error);
      });
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Suspense fallback={<LoaderSectionMainPage />}>
      <App />
    </Suspense>
  </BrowserRouter>
);

// Update web vitals reporting
reportWebVitals(metric => {
  // Send to analytics
  // console.log(metric);
});
